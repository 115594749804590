import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-export-csv',
  templateUrl: './export-csv.component.html',
  styleUrls: ['./export-csv.component.sass']
})
export class ExportCsvComponent implements OnInit {
  showingrecords = '';
  heading = 'Data Filter';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  cities: any;
  selectedcities = [];

  states: any;
  selectedstates = [];

  zips: any;
  selectedzips = [];

  vendor = 'tbl_master';
  vendors: any;
  vendorVal = '';

  from = '';
  to = '';
  totalrec = 0;
  records = [];
  constructor(private SpinnerService: NgxSpinnerService,public csvUpdateService: CsvUpdateService, private toastr: ToastrService) { }


  ngOnInit(): void {
    this.cities = this.csvUpdateService.citiesStates.map(({ city }) => ({ city }));
    this.cities = [...new Map(this.cities.map(item =>
      [item['city'], item])).values()];

    this.states = this.csvUpdateService.citiesStates.map(({ state }) => ({ state }));
    this.states = [...new Map(this.states.map(item =>
      [item['state'], item])).values()];

    this.totalrec = 0;
    this.GetVendors();


    this.csvUpdateService.GetZipcodes()
      .subscribe(
        response => {
          this.zips = response;
        },
        error => {
        });
  }

  RemoveZip(val) {
    this.selectedzips = this.selectedzips.filter(item => item != val);
  }
  selectZip(event) {
    var len = this.selectedzips.filter(item => item == event.zip).length;
    if (len == 0) {
      this.selectedzips.push(event.zip);
    }
  }

  RemoveState(val) {
    this.selectedstates = this.selectedstates.filter(item => item != val);
  }
  selectState(event) {
    var len = this.selectedstates.filter(item => item == event.state).length;
    if (len == 0) {
      this.selectedstates.push(event.state);
    }
  }

  RemoveCity(val) {
    this.selectedcities = this.selectedcities.filter(item => item != val);
  }
  selectCity(event) {
    var len = this.selectedcities.filter(item => item == event.city).length;
    if (len == 0) {
      this.selectedcities.push(event.city);
    }
  }

  GetVendors() {
    this.csvUpdateService.getvendors()
      .subscribe(
        response => {
          this.vendors = response;
        },
        error => {
        });
  }

  GetRecords() {
    if (this.vendor == '' &&
      this.selectedzips.length == 0
      && this.selectedcities.length == 0
      && this.selectedstates.length == 0
      && this.from == ''
      && this.to == ''
    ) {
      this.toastr.error('Please apply filters.', 'Error!');
    }
    else {
      var citystr = this.GetStrFromArr(this.selectedcities);
      var zipcodestr = this.GetStrFromArr(this.selectedzips);
      var statestr = this.GetStrFromArr(this.selectedstates);
      var vendor=this.vendors.filter(item => item.tbl == this.vendor)[0].vendor;
      var vend = "'[val]'";
      vend = vend.replace("[val]", vendor);
      const userid = localStorage.getItem("token");
      const data = {
        vendortbl: this.vendor,
        vendor: vendor,
        zipcode: zipcodestr,
        city: citystr,
        from: this.from,
        to: this.to,
        state: statestr,
        userid: userid,
      };
      this.SpinnerService.show();
      this.csvUpdateService.GetRecords(data)
        .subscribe(
          response => {
            this.SpinnerService.hide();
            this.records = response;
            this.totalrec = this.records[0].totalRec;
            if (this.totalrec > 10) {
              this.showingrecords = "showing first 10 records as sample";
            }
            else {
              this.showingrecords = "showing all " + this.totalrec + " records";
            }
          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }
  ExportCsv() {
    if (this.vendor == '' &&
      this.selectedzips.length == 0
      && this.selectedcities.length == 0
      && this.selectedstates.length == 0
      && this.from == ''
      && this.to == ''
    ) {
      this.toastr.error('Please apply filters.', 'Error!');
    }
    else {
      var citystr = this.GetStrFromArr(this.selectedcities);
      var zipcodestr = this.GetStrFromArr(this.selectedzips);
      var statestr = this.GetStrFromArr(this.selectedstates);
      var vendor=this.vendors.filter(item => item.tbl == this.vendor)[0].vendor;
      var vend = "'[val]'";
      vend = vend.replace("[val]", vendor);
      const userid = localStorage.getItem("token");
      const data = {
        vendortbl: this.vendor,
        vendor: vendor,
        zipcode: zipcodestr,
        city: citystr,
        from: this.from,
        to: this.to,
        state: statestr,
        userid: userid,
      };
      this.SpinnerService.show();
      this.csvUpdateService.GetAllRecords(data)
        .subscribe(
          items => {
            debugger;
            this.SpinnerService.hide();
            let csv

            var l = items.length;
            if (l > 0) {
              l = 1;
            }
            // Loop the array of objects
            for (let row = 0; row < 1; row++) {
              let keysAmount = Object.keys(items[row]).length
              let keysCounter = 0

              // If this is the first row, generate the headings
              if (row === 0) {

                // Loop each property of the object
                for (let key in items[row]) {
                  console.log(key);
                  // This is to not add a comma at the last cell
                  // The '\r\n' adds a new line
                  csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                  keysCounter++
                }
                csv = csv.replace("undefined", '');
              }
              keysCounter = 0
            }
            for (let row = 0; row < items.length; row++) {
              let keysAmount = Object.keys(items[row]).length
              let keysCounter = 0


              for (let key in items[row]) {
                csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
              }

              keysCounter = 0
            }
            var dd = encodeURI(csv);
            var link = document.createElement('a');
            link.href = 'data:text/csv;charset=utf-8,' + escape(csv);
            link.target = '_blank';
            link.download = 'export.csv';
            link.click();

          },
          error => {
            this.SpinnerService.hide();
            this.toastr.error('An error occurred, please try again later.', 'Error!');
          });
    }

  }
  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  GetStrFromArr(Arr) {
    if (Arr.length == 0) {
      return '';
    }
    else {
      var arr2 = [];
      for (let i = 0; i < Arr.length; i++) {
        var value = "'[val]'";
        value = value.replace("[val]", Arr[i]);
        arr2.push(value);
      }
      return arr2.toString()
    }
  }

}
