<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <a [routerLink]="['/addareas']"
      class="btn-shadow d-inline-flex align-items-center btn btn-success"><fa-icon [icon]="faPlus" class="mr-2"></fa-icon>Add</a>
    </div>
  </div>
</div>
