
<div [ngClass]="{
    'fixed-footer' : globals.toggleFixedFooter
    }" [class]="'app-container app-theme-white'">
    <app-header></app-header><br><br>
    <div class="app-main__outer1">
        <div class="app-main__inner1 bgimg" style="padding: 0px;">
            <div class="outerdiv">
                <div class="innerdiv">
                    <div>
                        <h6 class="mb-0" style="font-size: 1.3em;font-weight: normal;">Master Vendor</h6>
                    </div>
                    <div>
                        <p class="font-italic" style="font-size: 1em;">
                        </p>
                    </div>
                </div>
            </div>
            <div class="innerbox">
                <div class="mt-5 mb-2 text-center">
                </div>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group" style="text-align: left;">
                        <input placeholder="Enter your email" type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                    <div class="form-group" style="text-align: left;">
                        <input placeholder="Password" type="password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters
                            </div>
                        </div>
                    </div>
                    <button type="submit" style="background-color:#1dbf73;"
                    class="btn btn-block text-white font-weight-bold">Continue</button>
                </form>
            </div>
        </div>
        <footer class="page-footer font-small blue" style="background-color:#dcdddf;">
            <div class="footer-copyright text-center py-2">
                <span style="font-size: 13px;" class="pl-2">&#169;&#160;Master Vendor
                    Ltd.</span>
            </div>
        </footer>
    </div>
</div><ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#fff" type="ball-spin-rotate" [fullScreen]="true"
><div class=" loadingio-spinner-disk-c38836395o4">
 <div class="ldio-pebfw2megnf">
     <div>
         <div></div>
         <div></div>
     </div>
 </div>
 </div>
 <style type="text/css">
     @keyframes ldio-pebfw2megnf {
         0% {
             transform: rotate(0deg)
         }

         100% {
             transform: rotate(360deg)
         }
     }

     .ldio-pebfw2megnf>div>div {
         position: absolute;
         border-radius: 50%;
     }

     .ldio-pebfw2megnf>div>div:nth-child(1) {
         top: 30px;
         left: 30px;
         width: 140px;
         height: 140px;
         background: #041c54;
     }

     .ldio-pebfw2megnf>div>div:nth-child(2) {
         top: 39.800000000000004px;
         left: 79px;
         width: 42px;
         height: 42px;
         background: #ffffff;
         animation: ldio-pebfw2megnf 1s linear infinite;
         transform-origin: 21px 60.199999999999996px;
     }

     .loadingio-spinner-disk-c38836395o4 {
         width: 200px;
         height: 200px;
         display: inline-block;
         overflow: hidden;
     }

     .ldio-pebfw2megnf {
         width: 100%;
         height: 100%;
         position: relative;
         transform: translateZ(0) scale(1);
         backface-visibility: hidden;
         transform-origin: 0 0;
         /* see note above */
     }

     .ldio-pebfw2megnf div {
         box-sizing: content-box;
     }

     /* generated by https://loading.io/ */
 </style><p class="text-center" style="color: #041c54" > Please wait... </p>
</ngx-spinner>