<div class="card main-card mb-3">
  <div class="card-header">
    Basic
  </div>
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Country</th>
      <th scope="col">Area</th>
      <th scope="col">Population</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let country of countries; index as i">
      <th scope="row">{{ i + 1 }}</th>
      <td>
        <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="mr-2"
             style="width: 20px">
        {{ country.name }}
      </td>
      <td>{{ country.area | number }}</td>
      <td>{{ country.population | number }}</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="main-card mb-3 card">
  <div class="card-header">
    Active Users
  </div>
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th class="text-center">#</th>
        <th>Name</th>
        <th class="text-center">City</th>
        <th class="text-center">Status</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-center text-muted">#345</td>
        <td>
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left mr-3">
                <div class="widget-content-left">
                  <img width="40" class="rounded-circle" src="./assets/images/avatars/4.jpg" alt="">
                </div>
              </div>
              <div class="widget-content-left flex2">
                <div class="widget-heading">John Doe</div>
                <div class="widget-subheading opacity-7">Web Developer</div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">Madrid</td>
        <td class="text-center">
          <div class="badge badge-warning">Pending</div>
        </td>
        <td class="text-center">
          <button type="button" class="btn btn-primary btn-sm">Details</button>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted">#347</td>
        <td>
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left mr-3">
                <div class="widget-content-left">
                  <img width="40" class="rounded-circle" src="./assets/images/avatars/3.jpg" alt="">
                </div>
              </div>
              <div class="widget-content-left flex2">
                <div class="widget-heading">Ruben Tillman</div>
                <div class="widget-subheading opacity-7">Etiam sit amet orci eget</div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">Berlin</td>
        <td class="text-center">
          <div class="badge badge-success">Completed</div>
        </td>
        <td class="text-center">
          <button type="button" class="btn btn-primary btn-sm">Details</button>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted">#321</td>
        <td>
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left mr-3">
                <div class="widget-content-left">
                  <img width="40" class="rounded-circle" src="./assets/images/avatars/2.jpg" alt="">
                </div>
              </div>
              <div class="widget-content-left flex2">
                <div class="widget-heading">Elliot Huber</div>
                <div class="widget-subheading opacity-7">Lorem ipsum dolor sic</div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">London</td>
        <td class="text-center">
          <div class="badge badge-danger">In Progress</div>
        </td>
        <td class="text-center">
          <button type="button" class="btn btn-primary btn-sm">Details</button>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted">#55</td>
        <td>
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left mr-3">
                <div class="widget-content-left">
                  <img width="40" class="rounded-circle" src="./assets/images/avatars/1.jpg" alt=""></div>
              </div>
              <div class="widget-content-left flex2">
                <div class="widget-heading">Vinnie Wagstaff</div>
                <div class="widget-subheading opacity-7">UI Designer</div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">Amsterdam</td>
        <td class="text-center">
          <div class="badge badge-info">On Hold</div>
        </td>
        <td class="text-center">
          <button type="button" class="btn btn-primary btn-sm">Details</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-block text-center card-footer">
    <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
      <i class="pe-7s-trash btn-icon-wrapper"> </i>
    </button>
    <button class="btn-wide btn btn-success">Save</button>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Company Agents Status</div>
  </div>
  <div class="table-responsive">
    <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
      <thead>
      <tr>
        <th class="text-center">#</th>
        <th class="text-center">Avatar</th>
        <th class="text-center">Name</th>
        <th class="text-center">Company</th>
        <th class="text-center">Status</th>
        <th class="text-center">Due Date</th>
        <th class="text-center">Target Achievement</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-center text-muted" style="width: 80px;">#54</td>
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/1.jpg" alt="">
        </td>
        <td class="text-center"><a [routerLink]="[]">Juan C. Cargill</a></td>
        <td class="text-center"><a [routerLink]="[]">Micro Electronics</a></td>
        <td class="text-center">
          <div class="badge badge-pill badge-danger">Canceled</div>
        </td>
        <td class="text-center">
                                        <span class="pr-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          12 Dec
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pr-2">
                  <div class="widget-numbers fsize-1 text-danger">71%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="71" aria-valuemin="0"
                         aria-valuemax="100" style="width: 71%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary">Hire</button>
            <button class="btn-shadow btn btn-primary">Fire</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted" style="width: 80px;">#55</td>
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/2.jpg" alt="">
        </td>
        <td class="text-center"><a [routerLink]="[]">Johnathan Phelan</a></td>
        <td class="text-center"><a [routerLink]="[]">Hatchworks</a></td>
        <td class="text-center">
          <div class="badge badge-pill badge-info">On Hold</div>
        </td>
        <td class="text-center">
                                        <span class="pr-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          12 Dec
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pr-2">
                  <div class="widget-numbers fsize-1 text-warning">54%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="54" aria-valuemin="0"
                         aria-valuemax="100" style="width: 54%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary">Hire</button>
            <button class="btn-shadow btn btn-primary">Fire</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted" style="width: 80px;">#56</td>
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/3.jpg" alt="">
        </td>
        <td class="text-center"><a [routerLink]="[]">Darrell Lowe</a></td>
        <td class="text-center"><a [routerLink]="[]">Riddle Electronics</a></td>
        <td class="text-center">
          <div class="badge badge-pill badge-warning">In Progress</div>
        </td>
        <td class="text-center">
                                        <span class="pr-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          12 Dec
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pr-2">
                  <div class="widget-numbers fsize-1 text-success">97%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-success" role="progressbar" aria-valuenow="97" aria-valuemin="0"
                         aria-valuemax="100" style="width: 97%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary">Hire</button>
            <button class="btn-shadow btn btn-primary">Fire</button>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center text-muted" style="width: 80px;">#56</td>
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/4.jpg" alt="">
        </td>
        <td class="text-center"><a [routerLink]="[]">George T. Cottrell</a></td>
        <td class="text-center"><a [routerLink]="[]">Pixelcloud</a></td>
        <td class="text-center">
          <div class="badge badge-pill badge-success">Completed</div>
        </td>
        <td class="text-center">
                                        <span class="pr-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          12 Dec
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pr-2">
                  <div class="widget-numbers fsize-1 text-info">88%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-info" role="progressbar" aria-valuenow="88" aria-valuemin="0"
                         aria-valuemax="100" style="width: 88%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button class="btn-shadow btn btn-primary">Hire</button>
            <button class="btn-shadow btn btn-primary">Fire</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-block p-4 text-center card-footer">
    <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
      <span class="mr-2 opacity-7">
          <i class="fa fa-cog fa-spin"></i>
      </span>
      <span class="mr-1">View Complete Report</span>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Simple table</h5>
        <table class="mb-0 table">
          <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Table bordered</h5>
        <table class="mb-0 table table-bordered">
          <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Table without border</h5>
        <table class="mb-0 table table-borderless">
          <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Table dark</h5>
        <table class="mb-0 table table-dark">
          <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Table with hover</h5>
        <table class="mb-0 table table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Table responsive</h5>
        <div class="table-responsive">
          <table class="mb-0 table">
            <thead>
            <tr>
              <th>#</th>
              <th>Table heading</th>
              <th>Table heading</th>
              <th>Table heading</th>
              <th>Table heading</th>
              <th>Table heading</th>
              <th>Table heading</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
              <td>Table cell</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Table sizing</h5>
        <table class="mb-0 table table-sm">
          <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Table striped</h5>
        <table class="mb-0 table table-striped">
          <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry</td>
            <td>the Bird</td>
            <td>@twitter</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
