import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']

})
export class SidebarComponent implements OnInit {
  websiteLogo: string;
  isLoggedInUser: boolean;
  isAdminUser: boolean;
  public extraParameter: any;

  constructor(private SpinnerService: NgxSpinnerService, private router: Router, public globals: ThemeOptions, private activatedRoute: ActivatedRoute) {
    this.isLoggedInUser = false;
    this.isAdminUser = true;
  }

  getItem() {
    //debugger;
    return 'admin';
  }
  ngOnInit() {

    let userid = localStorage.getItem('token');
    if (userid == null || userid == '') {
      //this.router.navigate(['/sign-in']);
    } else {

      this.isLoggedInUser = true;
    }
    
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      // if (this.innerWidth < 1200) {
      //   this.globals.toggleSidebar = true;
      // }
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
      else{
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;

  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';
  activeId1 = 'resultMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    // if (this.newInnerWidth < 1200) {
    //   this.globals.toggleSidebar = true;
    // } else {
    //   this.globals.toggleSidebar = false;
    // }
    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = true;
    }

  }
  Logout($event) {
    localStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear()
    let userid = localStorage.getItem('token');
    if (userid == null || userid == '') {
      this.router.navigate(['/sign-in'])
        .then(() => {
          window.location.reload();
        });
    }
  }
}
