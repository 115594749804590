<style>
  .bgimg {
	/* background-image: url('https://res.cloudinary.com/dx664fvxz/image/upload/v1662389055/OPM%20Website%20Data/logo.png'); */
	background-repeat: no-repeat;
}
@media only screen and (max-width: 320px) and (min-width:1px) {
	.bgimg {
		/* background-position: 85px bottom; */
		background-position: right bottom;
		background-size: 70%;
	}
}

@media only screen and (max-width: 375px) and (min-width:321px) {
	.bgimg {
		/* background-position: 135px bottom; */
		background-position: right bottom;
		background-size: 60%;
	}
}

@media only screen and (max-width: 425px) and (min-width:376px) {
	.bgimg {
		/* background-position: 185px bottom; */
		background-position: right bottom;
		background-size: 53%;
	}
}

@media only screen and (max-width: 768px) and (min-width:426px) {
	.bgimg {
		/* background-position: 620px bottom; */
		background-position: right bottom;
		background-size: 21%;
	}
}

@media only screen and (max-width: 1024px) and (min-width:769px) {
	.bgimg {
		/* background-position: 725px bottom; */
		background-position: right bottom;
		background-size: 20%;
	}
}

@media only screen and (max-width: 1440px) and (min-width:1025px) {
	.bgimg {
		/* background-position: 950px bottom; */
		background-position: right bottom;
		background-size: 18%;
	}
}

@media only screen and (max-width: 2560px) and (min-width:1441px) {
	.bgimg {
		/* background-position: 960px bottom; */
		background-position: right bottom;
	}
}

@media only screen and (min-width:2561px) {
	.bgimg {
		background-position: right bottom;
	}
}
</style>
<!--- Base Layout-->

<div [ngClass]="{
'closed-sidebar' : globals.toggleSidebar,
'closed-sidebar-md' : globals.toggleSidebarMobile,
'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
'header-menu-open' : globals.toggleHeaderMobile,
'fixed-footer' : globals.toggleFixedFooter
}" [class]="'app-container app-theme-white'">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  

  <div class="app-main__outer">
    <div class="app-main__inner bgimg">
      <div [@architectUIAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
</div>
<ngx-loading-bar [color]="'var(--primary)'"></ngx-loading-bar>

<!-- Base-Layout-Ends -->

