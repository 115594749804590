<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <button (click)="back()" class="mb-2 mr-2 btn-icon d-inline-flex align-items-center btn btn-dark text-white"><i class="pe-7s-back-2 btn-icon-wrapper"></i>&nbsp;Back</button>
    </div>
  </div>
</div>
