import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../../../services/user.service';
import { ThemeOptions } from '../../../../../theme-options';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit {
  UserData:any=[];
  isLoggedInUser: boolean;
  UserProfilePic:any;
  ProfileImageName:string;
  constructor(private toastr: ToastrService,private userservice: UserService,private SpinnerService: NgxSpinnerService,public globals: ThemeOptions, private router: Router) {
    this.isLoggedInUser = false;
    // this.isAdminUser = false;
  }

  ngOnInit() {
    //debugger;
    let userid = localStorage.getItem('token');
    if (userid == null || userid == '') {
      //this.router.navigate(['/sign-in']);
    } else {

      this.isLoggedInUser = true;
    }
  }
  Logout($event) {
    localStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear()
    let userid = localStorage.getItem('token');
    if (userid == null || userid == '') {
      this.router.navigate(['/sign-in'])
        .then(() => {
          window.location.reload();
        });
    }
  }
  
}
