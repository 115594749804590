<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
      </div>
    </div>
  </div>
</div>

<div class="main-card mb-3 card">
  <div class="card-body cardbody">
    <div class="position-relative form-group">
      <div>
        <div class="custom-checkbox custom-control">
          <input type="checkbox" id="exampleCustomCheckbox" [(ngModel)]="hideExecutionTime" (change)="hideshowExecutionTime(hideExecutionTime?'1':'2')"
                 class="custom-control-input"><label for="exampleCustomCheckbox" class="custom-control-label">Show logs without execution time</label>
        </div>
      </div>
    </div>
  </div>
</div>

<p-table #dt1 [resizableColumns]="true" stateStorage="local" stateKey="dataPreview"
         [value]="records" [scrollable]="true" scrollHeight="400px" scrollDirection="both"
         responsiveLayout="stack" dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
         styleClass="p-datatable-offlineconsultants" [paginator]="true"
         [ngClass]="records.length > 0 ? 'heightauto' : 'height0'"
         currentPageReportTemplate="{first} to {last} of {totalRecords}">

  <ng-template pTemplate="header">
    <tr>
      <th>Details</th>
    </tr>

  </ng-template>
  <ng-template pTemplate="body" let-Items>
    <tr>
      <td>{{Items.description}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>




<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
