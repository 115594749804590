import {Component, Input} from '@angular/core';
import { faStar, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';

@Component({
  selector: 'page-back-button',
  templateUrl: './page-back-button.component.html',
})
export class BackButtonTitleComponent {
  constructor(private location: Location){}
  back(): void {
    this.location.back()
  }
  faStar = faStar;
  faPlus = faPlus;

  @Input() heading;
  @Input() subheading;
  @Input() icon;

}
