<div class="cont">
    <form action="">
      <fieldset>
        <legend><b>Import Csv</b></legend>
        <p>
          CSV File : <input #fileUploadSimple [accept]="'.csv'" type="file" class="input-controls"
                            (change)="importDataFromCSV($event)">
        </p>
        <p> <button type="button" class="btnUrility" (click)="UploadCsv()">Upload</button></p>
        <p *ngIf="skiprecs != ''">Skip Records: {{skiprecs}}</p>
  
        <p>
          Select Table
          <select id="tblImport" (change)="selectTable($event)">
            <option value=""></option>
            <option *ngFor="let t of tables" [value]="t.tblName">
              {{t.tblName}}
            </option>
          </select>
        </p>
        <p> <button type="button" class="btnUrility width180" (click)="DeleteDuplicate()">Remove duplicates</button></p>
        <p> <button type="button" class="btnUrility width180" (click)="ExportCsv()">Export Csv</button></p>
      </fieldset>
  
      <fieldset>
        <legend><b>Combine Tables</b></legend>
  
        <p class="combineTables">
          Select Table1
          <select id="tbl1Combine" class="combineSelect" (change)="selectCombineTable1($event)">
            <option value=""></option>
            <option *ngFor="let t of tables" [value]="t.tblName">
              {{t.tblName}}
            </option>
          </select>
        </p>
        <p class="combineTables">
          Select Table2
          <select id="tbl2Combine" class="combineSelect" (change)="selectCombineTable2($event)">
            <option value=""></option>
            <option *ngFor="let t of tablesCombine" [value]="t.tblName">
              {{t.tblName}}
            </option>
          </select>
        </p>
        <p class="combineTables">
          Table Name
          <input type="text" [(ngModel)]="combineTableName"  name="combineTableName" class="combineSelect width100txt" />
        </p>
        <table>
          <thead>
            <tr>
              <th scope="col">Column</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of tables1">
              <td data-label="Column">{{t.name}}</td>
              <td>
                <select id={{t.tblName}} class="combineSelect" (change)="selectColumn($event,t.name)">
                  <option value="null">Null</option>
                  <option *ngFor="let t2 of tables2" [value]="t2.name">
                    {{t2.name}}
                  </option>
                </select>
              </td>
            </tr>
  
          </tbody>
        </table>
        <p class="clearboth"></p>
        <p> <button type="button" class="btnUrility width180" (click)="CombineTables()">Combine tables</button></p>
      </fieldset>
  
  
  
      <fieldset>
        <legend><b>Subtract Tables</b></legend>
  
        <p class="combineTables">
          Select Table1
          <select id="tbl1Subtract" class="combineSelect" (change)="selectSubtractTable1($event)">
            <option value=""></option>
            <option *ngFor="let t of tables" [value]="t.tblName">
              {{t.tblName}}
            </option>
          </select>
        </p>
        <p class="combineTables">
          Select Table2
          <select id="tbl2Subtract" class="combineSelect" (change)="selectSubtractTable2($event)">
            <option value=""></option>
            <option *ngFor="let t of tablesSubtract" [value]="t.tblName">
              {{t.tblName}}
            </option>
          </select>
        </p>
        <p class="combineTables">
          Table Name
          <input type="text" [(ngModel)]="SubtractTableName" name="SubtractTableName" class="combineSelect width100txt" />
        </p>
        <table>
          <thead>
            <tr>
              <th scope="col"><small>Select Email Column(Table1)</small></th>
              <th scope="col"><small>Select Email Column(Table2)</small></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <select id="subtract1" class="combineSelect" (change)="selectSubtractColumn1($event)">
                  <option [value]="" [disabled]="disabled"></option>
                  <option *ngFor="let t2 of colsubtables1" [value]="t2.name">
                    {{t2.name}}
                  </option>
                </select>
              </td>
              <td>
                <select id="subtract2" class="combineSelect" (change)="selectSubtractColumn2($event)">
                  <option [value]="" [disabled]="disabled"></option>
                  <option *ngFor="let t2 of colsubtables2" [value]="t2.name">
                    {{t2.name}}
                  </option>
                </select>
              </td>
            </tr>
  
          </tbody>
        </table>
        <p class="clearboth"></p>
        <p> <button type="button" class="btnUrility width180" (click)="SubtractTables()">Subtract tables</button></p>
      </fieldset>
    </form>
  </div>
  