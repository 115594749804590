import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CsvUpdateService } from 'src/app/services/csv-update.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LogsService } from '../../services/logs.service';
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.sass']
})
export class LogsComponent implements OnInit {
  showingrecords = '';
  heading = 'Logs';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  hideExecutionTime = true;
  records = [];
  constructor(private SpinnerService: NgxSpinnerService, public logsService: LogsService, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.GetRecords();
  }
  hideshowExecutionTime(event: any) {
    if (event == '1') {
      this.hideExecutionTime = true;
    }
    else {
      this.hideExecutionTime = false;
    }
    this.GetRecords();
  }
  GetRecords() {
    const data = {
      exTime: this.hideExecutionTime,
    };
    this.SpinnerService.show();
    this.logsService.GetLogs(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.records = response;
        },
        error => {
          this.SpinnerService.hide();
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        });
  }
 
}
