<div *ngIf="isLoggedInUser" class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-link p-0 mr-2 text-decoration-none" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <div *ngIf="UserProfilePic">
                    <img  src="{{UserProfilePic}}"
                    class="profileImage rounded-circle" />
                  </div>
                  <div *ngIf="!UserProfilePic">
                      <div class="profileImage rounded-circle">{{ProfileImageName}}</div>
                  </div>
                </div>
              </span>
            </button>
            <div  class="dropdown-menu" ngbDropdownMenu >
                <button class="dropdown-item" (click)="Logout($event)"><i class="fa fa-power-off mr-2" style="color:#041c54"></i>Log Out</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

