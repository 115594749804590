import {Component, HostBinding, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import {ThemeOptions} from '../../../theme-options';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit  {
  isLoggedInUser: boolean;
  faEllipsisV = faEllipsisV;
  searchresultpage:boolean;
  addconsultantoffline=false;
  constructor(public router: Router,public globals: ThemeOptions) {    this.isLoggedInUser = false;this.searchresultpage=false;

  }
  ngOnInit() {
   
    // debugger;
    if(this.router.url=='/submit-your-cv')
    {
      this.addconsultantoffline=true;
    }
    else{
      this.addconsultantoffline=false;
    }
    //debugger;
    let token = localStorage.getItem('token');
    if (token == null || token == '') {
      //this.router.navigate(['/sign-in']);
    } else {
      this.isLoggedInUser = true;
      // this.isAdminUser = localStorage.getItem('role')=="user"?false:true;
    }
  }
  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  @select('config') public config$: Observable<any>;
  getItem() {
    return localStorage.getItem('role');
}
  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

}
