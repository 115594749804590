import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../DemoPages/common/global';

@Injectable({
  providedIn: 'root'
})
export class SftpService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = Global.apiURL + '/api/';
  }
  DownloadFile(data): Observable<any> {
    return this.http.post(this.baseUrl + 'DownloadFile', data, {
      responseType: 'blob',
    });
  }
  getSFTPDirectory(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getSFTPDirectory', data);
  }
  AddFtpSftpAccount(data): Observable<any> {
    return this.http.post(this.baseUrl + 'AddFtpSftpAccount', data);
  }
 UpdateFtpSftpAccount(data): Observable<any> {
   return this.http.post(this.baseUrl + 'UpdateFtpSftpAccount', data);
  }
  getFilesBySFTPDirectory(data): Observable<any> {
    return this.http.post(this.baseUrl + 'getFilesBySFTPDirectory', data);
  }
  GetSftpAccounts(): Observable<any> {
    return this.http.get(this.baseUrl + 'GetSftpAccounts');
  }
  
  GetSftpAccountById(data): Observable<any> {
    return this.http.post(this.baseUrl + 'GetSftpAccountById', data);
  }

}
