<div *ngIf="isLoggedInUser" class="search-wrapper" [class.active]="isActive">
  <form [formGroup]="form" (ngSubmit)="Search()">

  <div class="input-holder">
    <input type="text" placeholder="Type to search" formControlName="name"  id="name"
    name="name" class="search-input">
    <button class="search-icon" (click)="isActive = true"><span></span></button>
  </div>
  <button class="close" (click)="isActive = false"></button>
</form>
</div>
