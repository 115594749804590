import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  public isActive: any;
  isLoggedInUser: boolean;
  // isAdminUser: boolean;

  constructor(private router: Router, private formBuilder: FormBuilder, public globals: ThemeOptions, private activatedRoute: ActivatedRoute) {
    this.isLoggedInUser = false;
    // this.isAdminUser = false;
  }

  ngOnInit() {
    let userid = localStorage.getItem('user-id');
    if (userid == null || userid == '') {
      // this.router.navigate(['/sign-in']);
    } else {
      this.isLoggedInUser = true;
      // this.isAdminUser = localStorage.getItem('role')=="user"?false:true;
    }
    this.form = this.formBuilder.group({
      name: ''
    });
  }
  get f() { return this.form.controls; }
  Search() {
    if (this.f.name.value != '') {
      this.submitted = true;
      this.router.navigate(['app/searchresults/' + this.f.name.value]);
    }

  }

}
