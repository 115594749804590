<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
            </div>
        </div>
    </div>
</div>
<div class="mb-3 card">
    <div class="card-body">
        <h5 class="card-title"></h5>
        <div class="row">
            <div class="col-md-3 floatleft position-relative form-group margintop8">
                <label for="exampleFile">Vendor</label>
                <select class="mb-2 form-control-md form-control" [(ngModel)]="vendor" (change)="loadColumns($event,mymodal)">
                    <option  [value]=""></option>
                    <option *ngFor="let v of vendors" [value]="v.tbl">
                        {{v.vendor}}
                    </option>
                </select>
            </div>
            <div class="col-md-3 floatleft position-relative form-group">
                <label for="exampleFile">Zip</label>
                <ngx-autocomplete [searchKeyword]="'zip'" [inputId]="'searchZip'" [placeholderValue]="'Enter Zip'"
                    [entries]="zips" (selectedValue)="selectZip($event)">
                </ngx-autocomplete>
                <div class="font-icon-wrapper font-icon-sm " *ngIf="selectedzips.length > 0"><span class="spanselected"
                        *ngFor="let z of selectedzips; index as i;">{{z}}<i (click)="RemoveZip(z)"
                            class="pe-7s-close-circle icon-gradient bg-plum-plate"> </i></span></div>
            </div>
            <div class="col-md-3 floatleft position-relative form-group">
                <label for="exampleFile">City</label>
                <ngx-autocomplete [searchKeyword]="'city'" [inputId]="'searchCity'"
                    [placeholderValue]="'Enter City Name'" [entries]="cities" (selectedValue)="selectCity($event)">
                </ngx-autocomplete>
                <div class="font-icon-wrapper font-icon-sm " *ngIf="selectedcities.length > 0"><span
                        class="spanselected" *ngFor="let z of selectedcities; index as i;">{{z}}<i
                            (click)="RemoveCity(z)" class="pe-7s-close-circle icon-gradient bg-plum-plate"> </i></span>
                </div>
            </div>

            <div class="col-md-3 floatleft position-relative form-group">
                <label for="exampleFile">State</label>
                <ngx-autocomplete [searchKeyword]="'state'" [inputId]="'searchState'"
                    [placeholderValue]="'Enter State Name'" [entries]="states" (selectedValue)="selectState($event)">
                </ngx-autocomplete>
                <div class="font-icon-wrapper font-icon-sm " *ngIf="selectedstates.length > 0"><span
                        class="spanselected" *ngFor="let z of selectedstates; index as i;">{{z}}<i
                            (click)="RemoveState(z)" class="pe-7s-close-circle icon-gradient bg-plum-plate"> </i></span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 floatleft position-relative form-group">
                <label for="exampleEmail">From</label>
                <input name="from" placeholder="" [(ngModel)]="from" [ngModelOptions]="{standalone: true}" type="date"
                    class="form-control">
            </div>
            <div class="col-md-3 floatleft position-relative form-group">
                <label for="exampleEmail">To</label>
                <input name="to" placeholder="" [(ngModel)]="to" [ngModelOptions]="{standalone: true}" type="date"
                    class="form-control">
            </div>
            <div class="col-md-3 floatleft position-relative form-group paddingtop25">
                <button type="button" class="mt-1 btn btn-primary" (click)="GetRecords()">Filter</button>
            </div>
        </div>
    </div>
</div>
<h2 [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">Preview of data: ({{showingrecords}})</h2>
<h2 class="floatleft margintop5" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'">Total records: {{totalrec}}
</h2><button type="button" class="mt-1 btn btn-success marginleft3"
    [ngClass]="records.length > 0 ? 'heightauto' : 'height0'" (click)="ExportCsv()">Export</button>
<div class="clearboth"></div>

<p-table #dt1 [resizableColumns]="true" stateStorage="local" stateKey="dataPreview" [columns]="selectedCols" [value]="records"
         [scrollable]="true" scrollHeight="400px" scrollDirection="both" responsiveLayout="stack" dataKey="id" [rows]="10"
         [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-offlineconsultants"
         [paginator]="false" [ngClass]="records.length > 0 ? 'heightauto' : 'height0'"
         currentPageReportTemplate="">

  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of selectedCols; index as i;">{{col.column}}</th>
    </tr>

  </ng-template>
  
  <ng-template pTemplate="body" let-rowData let-columns="selectedCols">
    <tr>
      <td *ngFor="let col of selectedCols">
        {{rowData[col.column]}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No record found.</td>
    </tr>
  </ng-template>
</p-table>
<br />
<br />
<ng-template class="modalField" #mymodal let-modal>
    <div class="modal-dialog" role="document">
        <div class="modal-content whitebg">
            <div class="modal-header">
                <h5 class="modal-title">Select Columns</h5>
                <!-- close btn btn-round -->
                <button _ngcontent-c1="" aria-label="Close" class="closebtn" type="button"
                    (click)="modal.dismiss('Cross click')">
                    <span _ngcontent-c1="" class="closespan" aria-hidden="true">×</span>
                </button>
            </div>
            <!--end of modal head-->
            <div class="modal-body">
                <table id="tblColumns">
                    <thead>
                        <tr>
                            <th scope="col">Column</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of selectedColumns; index as i;">
                            <td data-label="Column">{{t.column}}</td>
                            <td>
                                <div class="custom-checkbox custom-control"><input type="checkbox" id="col{{i}}"
                                        [(ngModel)]="t.selectedValue" [ngModelOptions]="{standalone: true}"
                                        class="custom-control-input"><label for="col{{i}}"
                                        class="custom-control-label"></label>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <div>
                </div>
            </div>
            <!--end of modal body-->
            <div class="modal-footer">
                <button role="button" class="btn btn-success" type="button" (click)="ConfirmSelectedColumns()">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</ng-template>


<ngx-spinner  bdOpacity = 0.1
              bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen] = "false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
