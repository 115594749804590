<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <a [routerLink]="['/csv-parser']"
      class="btn-shadow d-inline-flex align-items-center btn btn-dark">Back</a>
    </div>
  </div>
</div>
