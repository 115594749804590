<div *ngIf="!isLoggedInUser">
  <div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
    <div class="app-header__content" style=" display: flex;
    align-items: center;
    padding: 0 6.5%;">
      <div class="app-header-left">
        <div class="site-logo">
          <a routerLink="/sign-in" >
            
          </a>
        </div>
      </div>
      <div class="app-header-right">
        <button *ngIf="router.url.split('?')[0] == '/pages/reset-password'"
          [routerLink]="['/sign-in']" class="btn btn-sm btndesign">
          Login
        </button>
        <button *ngIf="router.url === '/pages/forgot-your-password'"
          [routerLink]="['/sign-in']" class="btn btn-sm btndesign">
          Login
        </button>
       
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div class="mobilehdrposition">
        <div class="app-header-left">
          <div class="site-logo">
            <a routerLink="/sign-in" ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="app-header__menu" *ngIf="router.url != '/submit-your-cv'">
      <div class="btn-group" ngbDropdown placement="bottom-right">
        <button class="btn-icon btn-icon-only btn btn-primary" ngbDropdownToggle>
          <div class="btn-icon-wrapper">
            <fa-icon [icon]="faEllipsisV"></fa-icon>
          </div>
        </button>
        <div ngbDropdownMenu class="vsm-item">
          <a *ngIf="router.url === '/pages/forgot-your-password'"
            [routerLink]="['/sign-in']" class="dropdown-item"><i class="fa fa-power-off mr-2" style="color:#041c54"></i>
            Login
          </a>
          <a *ngIf="router.url.split('?')[0] == '/pages/reset-password'"
            [routerLink]="['/sign-in']" class="dropdown-item"><i class="fa fa-power-off mr-2" style="color:#041c54"></i>
            Login
          </a>
          
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isLoggedInUser">
  <div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
    <div class="app-header__content" *ngIf="router.url === '/submit-your-cv'" style=" display: flex;
    align-items: center;
    padding: 0 6.5%;">
      <div class="app-header-left">
        <div class="site-logo">
          <a routerLink="/sign-in" ></a>
        </div>
      </div>
    </div>
    <!-- Change Password & Reset -->
    <div class="app-header__content" *ngIf="router.url === '/pages/change-password' || router.url.split('?')[0] == '/pages/reset-password'" style=" display: flex;
    align-items: center;
    padding: 0 6.5%;">
      <div class="app-header-left">
        <div class="site-logo">
          <a routerLink="/sign-in" ></a>
        </div>
      </div>
      <div class="app-header-right">
        <button *ngIf="getItem() == 'consultant'" [routerLink]="['/consultantdashboard']" class="btn btn-sm btndesign">
          Dashboard
        </button>
        <button *ngIf="getItem() != 'consultant'" [routerLink]="['/csv-parser']"
          class="btn btn-sm btndesign">
          Dashboard
        </button>
      </div>
    </div>
      <!--  -->
      <div class="app-header__mobile-menu" *ngIf="router.url === '/pages/change-password' || router.url.split('?')[0] == '/pages/reset-password'">
        <div class="mobilehdrposition">
          <div class="app-header-left">
            <div class="site-logo">
              <a routerLink="/sign-in" ></a>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="app-header__menu" *ngIf="router.url === '/pages/change-password' || router.url.split('?')[0] == '/pages/reset-password'">
        <div class="btn-group" ngbDropdown placement="bottom-right">
          <button class="btn-icon btn-icon-only btn btn-primary" ngbDropdownToggle>
            <div class="btn-icon-wrapper">
              <fa-icon [icon]="faEllipsisV"></fa-icon>
            </div>
          </button>
          <div ngbDropdownMenu class="vsm-item">
            <a *ngIf="getItem() == 'consultant'" [routerLink]="['/consultantdashboard']" class="dropdown-item"><i class="fa fa-plane mr-2" style="color:#041c54"></i>
              Dashboard
            </a>
            <a *ngIf="getItem() != 'consultant'" [routerLink]="['/csv-parser']" class="dropdown-item"><i class="fa fa-plane mr-2" style="color:#041c54"></i>
              Dashboard
            </a>

          </div>
        </div>
      </div>
    <!-- ************************************* -->

    <!-- Dashboard + Consultant(block) -->
    <div class="app-header__content" *ngIf="router.url != '/submit-your-cv' && router.url != '/pages/change-password'  && router.url.split('?')[0] != '/pages/reset-password'  && getItem() != 'consultant'">
      <div class="app-header-left">
        <app-search-box></app-search-box>
      </div>
      <div class="app-header-right">
        <app-user-box></app-user-box>
      </div>
    </div>
      <!--  -->
      <div class="app-header__mobile-menu" *ngIf="router.url != '/submit-your-cv' && router.url != '/pages/change-password' && router.url.split('?')[0] != '/pages/reset-password' ">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
          [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <!--  -->
      <div class="app-header__menu" *ngIf="router.url != '/submit-your-cv' && router.url != '/pages/change-password' && router.url.split('?')[0] != '/pages/reset-password' && getItem() != 'consultant'">
        <app-user-box></app-user-box>
      </div>
      <!-- ************************************* -->
  </div>
</div>



<!-- <div *ngIf="isLoggedInUser && getItem() == 'consultant'">
    <div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
       <div *ngIf="mainpage" class="app-header-left" style="    display: flex;
    align-items: center;
    padding: 0 0 0 1.5rem;">
      <div class="site-logo">
        <a routerLink="/sign-in" ></a>
      </div>
    </div>
      <div *ngIf="mainpage" class="app-header__content" style="padding: 0;">
      <div class="app-header-right">
        <div class="header-items">
          <a [routerLink]="['/consultantdashboard']" class="">Dashboard</a>
        </div>
      </div>
    </div> 
     <div *ngIf="!mainpage" class="app-header__mobile-menu">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div> 
      <div *ngIf="mainpage" class="app-header__menu">
      <div class="btn-group" ngbDropdown placement="bottom-right">
        <button class="btn-icon btn-icon-only btn btn-primary" ngbDropdownToggle>
          <div class="btn-icon-wrapper">
              <fa-icon [icon]="faEllipsisV"></fa-icon>
          </div>
      </button>
      <div ngbDropdownMenu class="vsm-item">
        <a routerLinkActive="active-item" routerLink="/consultantdashboard" class="dropdown-item vsm-link">Dashboard</a>
      </div>
      </div>
    </div>
    </div>
  </div> -->