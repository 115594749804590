import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../DemoPages/common/global';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    baseUrl: string;
    constructor(private http: HttpClient) {
      this.baseUrl = Global.apiURL + '/api/';
    }
  
    loginuser(data): Observable<any> {
      return this.http.post(this.baseUrl + 'loginuser', data);
    }
  }
  