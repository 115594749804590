import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  websiteName: string ;
  websitecopyrighttext: SafeHtml ;

  constructor(private SpinnerService: NgxSpinnerService,private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }
  

}
