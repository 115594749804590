import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SftpService } from 'src/app/services/sftp.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-sftp-lyson',
  templateUrl: './sftp-lyson.component.html',
  styleUrls: ['./sftp-lyson.component.css']
})
export class SftpLysonComponent implements OnInit {
  heading = 'SFTP Lyson';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';

  path = '';
  prev = '';
  submitted = false;

  dir = '';
  dirs: any;
  files: any;
  accounts: any;
  accountId = '3';
  constructor(private router: Router, private SftpService: SftpService, private SpinnerService: NgxSpinnerService) {
  }
  
  

  ngOnInit(): void {
    this.GetSftpAccounts();
    this.getSFTPDirectory('');
  }

  GetSftpAccounts() {
    this.SpinnerService.show();
    this.SftpService.GetSftpAccounts()
      .subscribe(
        response => {
          this.accounts = response;
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }
  changeAccount(event) {
    this.path = '';
    if (event.target.value != '') {
      this.accountId = event.target.value;
      this.getSFTPDirectory('');
    }
    else {
      this.dirs = [];
    }
  }
  

  getSFTPDirectory(path) {
    const data = {
      account: this.accountId,
      path: path
    };
    this.SpinnerService.show();
    this.SftpService.getSFTPDirectory(data)
      .subscribe(
        response => {
          this.files = response.data;
          var d =this.files.sort((a, b) => (a.modifyTime < b.modifyTime ? -1 : 1));
          this.files = d;
          this.SpinnerService.hide();
        },
        error => {
          console.log(error);
          this.SpinnerService.hide();
        });
  }

  DownloadFile(file) {
    var remotePath = '';
    if (this.path == '') {
      remotePath = "/" + file;
    }
    else {
      remotePath = "/" + this.path + "/" + file;
    }

    const data = {
      filename: file,
      dir: this.path,
      account: this.accountId,
      remotePath: remotePath
    };
    this.SpinnerService.show();
    this.SftpService.DownloadFile(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          saveAs(response, data.filename);
        },
        error => {

          this.SpinnerService.hide();
          //this.toastr.error('An Error Occurred!', 'Error!');
        });
  }
  OpenFolder(path) {
    if (this.path == '') {
      this.path = this.path + path;
    }
    else {
      this.path = this.path + "/" + path;
    }
    this.getSFTPDirectory(this.path);
  }
  Back() {
    if (this.path.indexOf("/") !== -1) {
      var p = this.path.slice(0, this.path.lastIndexOf("/"));
      this.path = p;
    }
    else {
      this.path = "";
    }
    this.getSFTPDirectory(this.path);
  }

  GetDate(time) {
    var date = new Date(time)
    return date;
  }
  GetSize(bytes: number) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    //var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }
}
