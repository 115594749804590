<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
      </div>
    </div>
  </div>
</div>
<div class="form-group">
  <label>Select Account</label>
  <select class="form-control" [(ngModel)]="accountId" [ngModelOptions]="{standalone: true}" (change)="changeAccount($event)">
    <option value=""></option>
    <option *ngFor="let a of accounts" [value]="a.id">
      {{a.name}}
    </option>
  </select>
</div>

<div class="content-list">
  <div class="row content-list-head">
    <div class="col-auto">
      <h3>Files</h3>
    </div>
  </div>

  <button *ngIf="path != ''" type="button"
          (click)="Back()"
          class="btn btn-warning btn-sm">
    Back
  </button>
  <br />
  <span *ngIf="accountId != ''">/{{path}}</span>

  <div class="content-list-body row">
    <div class="col">

      <ul class="list-group list-group-activity dropzone-previews flex-column-reverse filter-list-1645442056585">
        <li class="list-group-item" data-t="null" data-i="null" data-l="null"
            data-e="null" *ngFor="let f of files; index as i;">
          <div class="media align-items-center" *ngIf="f.type == '-'">
            <ul class="avatars">
              <li>
                <div class="avatar bg-primary">
                  <svg _ngcontent-pqj-c154=""
                       xmlns="http://www.w3.org/2000/svg" height="24"
                       viewBox="0 0 24 24" width="24" class="filter-white">
                    <path _ngcontent-pqj-c154="" d="M0 0h24v24H0z"
                          fill="none"></path>
                    <path _ngcontent-pqj-c154=""
                          d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z">
                    </path>
                  </svg>
                </div>
              </li>
            </ul>
            <div class="media-body d-flex justify-content-between align-items-center">
              <div>
                <a [routerLink]="" (click)="DownloadFile(f.name)"
                   data-filter-by="text"
                   class="A-filter-by-text">{{f.name}} ({{GetSize(f.size)}})</a>
                

                <br>
                <span class="text-small SPAN-filter-by-text"
                      data-filter-by="text">{{GetDate(f.modifyTime) | date:'medium' }}</span>
              </div>
            </div>
          </div>

          <div class="media align-items-center" *ngIf="f.type == 'd'">
            <ul class="avatars">
              <li>
                <div class="avatar bg-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" id="mainIconPathAttribute" fill="orange"></path> </svg>
                </div>
              </li>
            </ul>
            <div class="media-body d-flex justify-content-between align-items-center">
              <div>
                <a [routerLink]="" (click)="OpenFolder(f.name)"
                   data-filter-by="text"
                   class="A-filter-by-text">{{f.name}}</a>
                <br>
                <span class="text-small SPAN-filter-by-text"
                      data-filter-by="text">{{GetSize(f.size)}}</span>

                <br>
                <span class="text-small SPAN-filter-by-text"
                      data-filter-by="text">{{GetDate(f.modifyTime) | date:'medium' }}</span>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</div>


<ngx-spinner bdOpacity=0.1
             bdColor="rgba(255,255,255,0.8)" size="default" color="#fff"
             [fullScreen]="false"
             template="<img src='https://res.cloudinary.com/softisans-com/image/upload/v1670407970/Data%20processing/processing-gif-image-3_eadsu6.gif' />">
</ngx-spinner>
